exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-i-nostri-piatti-tsx": () => import("./../../../src/pages/i-nostri-piatti.tsx" /* webpackChunkName: "component---src-pages-i-nostri-piatti-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-la-brigata-tsx": () => import("./../../../src/pages/la-brigata.tsx" /* webpackChunkName: "component---src-pages-la-brigata-tsx" */),
  "component---src-pages-la-cantina-tsx": () => import("./../../../src/pages/la-cantina.tsx" /* webpackChunkName: "component---src-pages-la-cantina-tsx" */),
  "component---src-pages-la-filosofia-jsx": () => import("./../../../src/pages/la-filosofia.jsx" /* webpackChunkName: "component---src-pages-la-filosofia-jsx" */),
  "component---src-pages-prenota-tsx": () => import("./../../../src/pages/prenota.tsx" /* webpackChunkName: "component---src-pages-prenota-tsx" */),
  "component---src-pages-ristorante-tsx": () => import("./../../../src/pages/ristorante.tsx" /* webpackChunkName: "component---src-pages-ristorante-tsx" */)
}

